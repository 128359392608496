import "slick-carousel";

import "slick-carousel/slick/slick.css";

$(document).ready(function() {
  $("#homeSlider").slick({
    dots: false,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // appendDots: $("#homeDots"),
    fade: true,
    asNavFor: $("#homeSliderContent"),
    // prevArrow: $("#homePrev"),
    // nextArrow: $("#homeNext")
  });
  $("#homeSliderContent").slick({
    dots: true,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: $("#homeDots"),
    pauseOnDotsHover: true,
    fade: true,
    asNavFor: $("#homeSlider"),
    prevArrow: $("#homePrev"),
    nextArrow: $("#homeNext")
  });
});

$("#homePrev, #homeNext").on("mouseEnter", function() {
  $("#homeSlider, #homeSliderContent").slick('slickPause');
});

$(".banner__block:first-child").addClass("banner__block--active");

$(".banner__block").on("click", event => {
  $(event.currentTarget).addClass("banner__block--active");
  $(event.currentTarget)
    .siblings()
    .removeClass("banner__block--active");
});
